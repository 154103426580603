import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import axios from 'axios';
import RecoilizeDebugger from 'recoilize';
import './index.css';
import RoutesConfig from './route';
import '../src/util/mixpanelConfig'; // Mixpanel 초기화 코드 import

axios.defaults.withCredentials = true;

function App() {
  return (
    <RecoilRoot>
      {process.env.NODE_ENV === 'development' && <RecoilizeDebugger />}
      <Router>
        <RoutesConfig />
      </Router>
    </RecoilRoot>
  );
}

export default App;
